var CharacterSet = require('characterset');

/**
 * @const
 * @type {string}
 */
var PREVIEW_API_USER = 'tk';

/**
 * @const
 * @type {string}
 */
var PREVIEW_API_TOKEN = 'UWiewzNGqfaXgl50sfTX597VqiNVO+VsJvzyIM5SabTjZinSwqaidRKypEX3LMfHdBUibNmD+PCRx/5yCXr4NA==';

/**
 * Angular factory that returns an instance of the font loader service.
 * @ngInject
 */
function FontLoaderService($window) {
  // $window.Typekit may not be available if the Typekit JS failed to load,
  // but make sure this case is handled gracefully.
  if ($window.Typekit) {
    $window.Typekit.user = PREVIEW_API_USER;
    $window.Typekit.token = PREVIEW_API_TOKEN;
  }

  // For some reason our frontend makes multiple calls
  // to load the same font. To avoid loading the same
  // font multiple times, we use a cache. ¯\_(ツ)_/¯
  var cache = {};

  return {
    /**
     * Update (augment) all the fonts loaded through
     * FontLoaderService.
     *
     * @param {string} text
     */
    updateFonts: function (text) {
      var unicode = new CharacterSet(text).toArray().map(function (c) {
        return 'U+' + c.toString(16);
      }).join(',');

      Object.keys(cache).forEach(function (family) {
        var font = cache[family];
        font.update(unicode).catch(function() {
          // Noop to silence uncaught promise reject errors
          // in the JS console.
        });
      });
    },

    /**
     * @param {string} cssName
     * @param {string} alias
     * @param {Object=} descriptors (optional)
     */
    loadFont: function(family, alias, descriptors) {
      // $window.Typekit may not be available if the Typekit JS failed to load,
      // but make sure this case is handled gracefully.
      if (!$window.Typekit) {
        return;
      }

      if (cache[family]) {
        return cache[family].load();
      } else {
        var font = cache[family] =
          new $window.Typekit.Font(family, alias, descriptors);

        return font.load().then(function() {
          $window.Typekit.fonts.add(font);
        }).catch(function() {
          // Noop to silence uncaught promise reject errors
          // in the JS console.
        });
      }
    }
  };
}

module.exports = FontLoaderService;
